import React, { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ArticleJsonLd } from 'next-seo';
import * as snippet from '@segment/snippet';
import { dateTime } from 'utils/date';
import { isDev } from 'utils/env';

interface PageParams {
  children: React.ReactNode;
  date?: Date;
  description?: string;
  image?: string;
  title?: string;
  keywords?: string[];
}

export const Page: FC<PageParams> = ({
  children,
  date,
  description,
  image,
  title = 'WorkGood | Save time. Learn shortcuts',
  keywords
}) => {
  const router = useRouter();
  const domain = 'https://workgood.io';
  const formattedTitle = title;
  const url = router && router.asPath ? router.asPath : undefined;
  const canonical = url && url === '/' ? domain : domain + url;
  const defaultImage = 'https://workgood.io/images/banner.jpg';
  const featuredImage = domain + image ?? defaultImage;
  const renderSnippet = () => {
    const opts = {
      apiKey: !isDev
        ? 'A15H7cc2FHzbaYjUVYZWhQbT6IUN2klY'
        : 'SOdnfbQ8zpIISOCGYHvMl7HAmeAstO5J',
      page: {
        name: 'Page Viewed',
        properties: {
          path: router.asPath
        }
      }
    };
    return isDev ? snippet.max(opts) : snippet.min(opts);
  };

  return (
    <>
      <Head>
        <title>{formattedTitle}</title>
        <script dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
        <meta charSet="utf-8" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        {description && <meta content={description} name="description" />}
        {keywords && <meta content={keywords.join(', ')} name="keywords" />}
        <meta content="follow, index" name="robots" />
        <meta name="theme-color" content="#09194a" />
        <meta content="#09194a" name="msapplication-TileColor" />
        <meta
          content="/favicons/browserconfig.xml"
          name="msapplication-config"
        />
        <link
          href="/favicons/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/favicons/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/favicons/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link href="/favicons/site.webmanifest" rel="manifest" />
        <link
          color="#09194a"
          href="/favicons/safari-pinned-tab.svg"
          rel="mask-icon"
        />
        <link href="/favicons/favicon.ico" rel="shortcut icon" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />{' '}
        <link
          crossOrigin=""
          href="https://fonts.gstatic.com/"
          rel="preconnect"
        />
        <link
          as="style"
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap"
          rel="preload"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap"
          media="print"
          onLoad={() => "this.media='all'"}
          rel="stylesheet"
        />
        {/* {url && <link href={canonical} rel="canonical" />} */}
        <meta content="en_US" property="og:locale" />
        {/* <meta content={formattedTitle} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content={canonical} property="og:url" /> */}
        <meta content="5e41b2275db646a5" name="yandex-verification" />
        <meta
          content="t28Kl2fGmZjIEgh6q3mGsf-7gGb8115VMQm1qbMMIKc"
          name="google-site-verification"
        />
        {/* {featuredImage && (
          <>
            <meta content={featuredImage} property="og:image" />
            <meta content={description} property="og:image:alt" />
          </>
        )} */}
        {date && (
          <>
            <meta content="article" property="og:type" />
            <meta content={dateTime(date)} property="article:published_time" />
          </>
        )}
        <meta content="summary_large_image" name="twitter:card" />
        {/* <meta content="@leeerob" name="twitter:site" />
        <meta content="@leeerob" name="twitter:creator" /> */}
      </Head>
      {children}
      {date && (
        <ArticleJsonLd
          authorName="Lee Robinson"
          dateModified={dateTime(date)}
          datePublished={dateTime(date)}
          description={description}
          images={[featuredImage]}
          publisherLogo="https://masteringnextjs.com/favicons/android-chrome-192x192.png"
          publisherName="Mastering Next.js"
          title={formattedTitle}
          url={canonical}
        />
      )}
    </>
  );
};

export default Page;
