import React, { ReactNode } from 'react';
import getSymbols from './Symbols';
import { Center, Flex, Text, Kbd } from '@chakra-ui/react';
import { KEY_ALIAS } from './constants';
import { Feature } from '@workgood/types';
import { getPlatform } from 'utils/device';
import { Platform } from 'utils/constants';

export enum BindingState {
  DEFAULT = 'default',
  SUCCESS = 'success',
  FAILED = 'failed',
  HIDDEN = 'hidden'
}
interface BindingProps {
  platform?: Platform;
  feature: Feature;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  type?: 'symbol' | 'text' | 'mixed';
  children?: ReactNode;
  mask?: boolean;
  state?: BindingState;
}

export const Binding: React.FC<BindingProps> = ({
  platform = Platform.WINDOWS,
  feature,
  type = 'mixed',
  size = 'md',
  mask = false,
  state = BindingState.DEFAULT
}) => {
  const layerStyles = {
    [BindingState.DEFAULT]: 'keybuttonBase',
    [BindingState.HIDDEN]: 'keybuttonHidden',
    [BindingState.SUCCESS]: 'keybuttonSuccess',
    [BindingState.FAILED]: 'keybuttonFailed'
  };
  const Symbols = getSymbols(getPlatform());

  const getText = (key) => {
    return mask ? '?' : KEY_ALIAS[key] ?? key;
  };

  const mapToCombo = (key: string, index: number) =>
    ['symbol', 'mixed'].includes(type) && Symbols[key] ? (
      <Flex direction="column" align="center" key={`${key}${index}`}>
        <Center
          layerStyle={`${layerStyles[state]}${size}`}
          key={`${key}${index}`}
          textTransform="uppercase"
        >
          <Flex direction="column">
            <Center
              height={5}
              width={5}
              textAlign="center"
              margin="auto"
              mb={type === 'mixed' ? 0.5 : 0}
            >
              {mask ? '?' : Symbols[key]}
            </Center>
          </Flex>
        </Center>
        {type === 'mixed' ? (
          <Text fontSize="x-small" color="gray.600" fontWeight="600">
            {' '}
            {mask ? '' : getText(key)}
          </Text>
        ) : (
          ''
        )}
      </Flex>
    ) : (
      <Flex direction="column" key={`${key}-${index}`}>
        {size === 'xs' ? (
          <Kbd>{getText(key)}</Kbd>
        ) : (
          <Flex
            key={key}
            layerStyle={`${layerStyles[state]}${size}`}
            align="center"
            justify="center"
          >
            {getText(key)}
          </Flex>
        )}
      </Flex>
    );

  return (
    <Flex align="flex-start">
      {feature[`${platform}_binding`]
        ?.split(' ')
        .map((combo) => combo.split('+').map(mapToCombo))
        .map((combo, i) => {
          return i > 0 ? (
            <Flex key={`${i}-${combo}`}>
              <Text
                fontSize={'xs'}
                color="gray.600"
                fontWeight={'semibold'}
                mx={1}
                lineHeight='30px'
              >
                then
                {/* <BiChevronRight size={20} /> */}
              </Text>
              {combo}
            </Flex>
          ) : (
            combo
          );
        })}
    </Flex>
  );
};

export default Binding;
