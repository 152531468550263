import { chakra, ThemingProps, useStyleConfig } from '@chakra-ui/react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { ReactElement } from 'react';
import Sizes from './sizes';

// TODO review props when NextJs is updated so we don't have to defined it here
/**
 * ? Because NextJs typing is preventing auto-suggest for layout, width and height,
 * ? we declare the styles differently in this component and will manage the switch
 * ? to NextJs typings when calling NextJs `next/image` component
 */
type LayoutValue = 'fixed' | 'intrinsic' | 'responsive' | undefined;

type LayoutAndSize =
  | {
      layout: 'fill';
    }
  | {
      layout: LayoutValue;
      height: number;
      width: number;
    };

/**
 * Types for the Image component itself
 */
type ImageProps = Pick<
  NextImageProps,
  | 'className'
  | 'loading'
  | 'objectFit'
  | 'objectPosition'
  | 'priority'
  | 'quality'
  | 'src'
  | 'unoptimized'
> &
  Pick<Required<NextImageProps>, 'alt'> &
  Pick<ThemingProps, 'variant'> & {
    dimensions?: [number, number];
    layout?: 'fill' | LayoutValue;
    params?: string[]; // cloudinary params
    sizes?: Sizes; // could be a string too, this one is just a way to make it easier
  };

const normalizeSrc = (src: string): string => {
  return src[0] === '/' ? src.slice(1) : src;
};
const cloudinaryLoader = (additionalParams?: string[]) => ({
  config,
  src,
  width,
  quality,
}: {
  config?: any;
  src: string;
  width?: number;
  quality?: string | number;
}): string => {
  // Demo: https://res.cloudinary.com/demo/image/upload/w_300,c_limit,q_auto/turtles.jpg
  const path = process.env.NEXT_PUBLIC_CLOUD_IMG_URL;
  const params = ['f_auto', 'c_limit', 'w_' + width, 'h_' + width, 'q_' + (quality || '100')];
  const paramsString = params.concat(additionalParams).join(',') + '/';
  return `${path}${paramsString}${normalizeSrc(src)}`;
};

/**
 * Wraps NextJs `next/image` component in Chakra's factory function
 * This is what will allow to use the theme and the styling properties on the component
 */
const ImageWithChakra = chakra(
  ({
    className,
    dimensions = [0, 0],
    layout = 'fill',
    loading,
    objectFit,
    objectPosition,
    priority,
    quality,
    sizes,
    src,
    unoptimized,
    params = [],
    ...nextjsInternals
  }: ImageProps): ReactElement => {
    /**
     * ? As explained earlier, NextJs typing is preventing auto-suggest for layout, width and height
     * ? Here we actually convert our component typing to NextJs typing
     */
    const [width, height] = dimensions;

    const layoutAndSize: LayoutAndSize =
      height > 0 || width > 0
        ? {
            height,
            layout: layout === 'fill' ? 'intrinsic' : layout,
            width
          }
        : {
            layout: 'fill'
          };

    return (
      <NextImage
        loader={cloudinaryLoader(params)}
        className={className}
        loading={loading}
        objectFit={objectFit}
        objectPosition={objectPosition}
        priority={priority}
        quality={quality}
        sizes={sizes}
        src={src}
        unoptimized={unoptimized}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...layoutAndSize}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...nextjsInternals}
      />
    );
  }
);

const Image = ({ variant, ...props }: ImageProps): ReactElement => {
  /**
   * ? This components serves as an interface to pass Chakra's styles
   * ? You can use the theme and/or styling properties (eg. backgroundColor='red.200')
   */
  const styles = useStyleConfig('Image', { variant });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ImageWithChakra sx={styles} {...props} />;
};

export default Image;
