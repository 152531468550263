import { FaWindows } from 'react-icons/fa';
import { Platform } from 'utils/constants';

const getSymbols = (platform: Platform) =>
  platform === Platform.MAC
    ? {
        command: '⌘',
        plus: '+',
        control: '⌃',
        shift: '⇧',
        option: '⌥',
        up: '↑',
        down: '↓',
        left: '←',
        right: '→',
        tab: '⇥',
        caps: '⇪',
        backspace: '⌫',
        delete: '⌫',
        enter: '↵',
        return: '↵',
        pageup: '⇞',
        pagedown: '⇟',
        escape: 'esc',
        meta: <FaWindows />,
        space: '␣'
      }
    : {
        command: '⌘',
        plus: '+',
        shift: '⇧',
        option: '⌥',
        up: '↑',
        down: '↓',
        left: '←',
        right: '→',
        tab: '⇥',
        caps: '⇪',
        backspace: '⌫',
        delete: '⌫',
        enter: '↵',
        return: '↵',
        pageup: '⇞',
        pagedown: '⇟',
        escape: 'esc',
        meta: <FaWindows />,
        space: '␣'
      };

//      "command": "⌘",
//   12      "option": "⌥ option",
//   13      "control": "⌃ ctrl",
//   14      "shift": "⇧ shift",
//   15      "up": "⬆",
//   16      "down": "⬇",

// packages/next/utils/keyboard/symbol.ts:
//   20
//   21    const macFormats = {
//   22      control: '⌃',
//   23      alt: '⌥',
//   24      option: '⌥',
//   25:     meta: '⌘',
//   26:     super: '⌘',
//   27:     cmd: '⌘',
//   28:     command: '⌘',
//   29    };

// command: <Command />,
// shift: <Shift />,
// option: <Option />,
// // control: <ChevronUp />,
// // ctrl: <ChevronUp />,
// up: <UpArrowAlt />,
// down: <DownArrowAlt />,
// left: <LeftArrowAlt />,
// right: <RightArrowAlt />,
// tab: <KeyboardTab />,
// caps: <Capslock />,
// backspace: <Backspace />,
// delete: <Backspace />,
// enter: <KeyboardReturn />,
// return: <KeyboardReturn />,
// meta: <Windows />,
// space: <SpaceBar />,
// };

export default getSymbols;
