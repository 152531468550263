import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  Stack,
  Text
} from '@chakra-ui/react';
import Image from 'next/image';
import router from 'next/router';
import * as React from 'react';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { getImageUrl } from 'utils/image';

export const BigFooter = () => (
  <Container
    as="footer"
    role="contentinfo"
    maxW={'100%'}
    bg="gray.300"
    px={{ base: 8, md: 12 }}
    pb={{ base: 16, md: 0 }}
  >
    <Stack
      spacing="8"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
      pt={{ base: 8, md: 2 }}
      pb={{ base: 4, md: 2 }}
    >
      <Stack
        spacing={{ base: 0, md: 0 }}
        align={{ base: 'center', md: 'start' }}
      >
        <Box ml={-1}>
          <Image
            height={24}
            width={100}
            src={getImageUrl('brand/full-transparent-plain-onwhite.png')}
            alt={`WorkGood`}
          />
        </Box>
        <Text color="muted">Save time. Learn shortcuts.</Text>
      </Stack>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', md: '2' }} alignItems="center">
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing="8"
        >
          <Stack spacing="4" minW="36" flex="1">
            <Flex justify={{ base: 'center', md: 'flex-end' }} w="100%">
              {/* <Button
                as="a"
                variant="link"
                cursor={'pointer'}
                color="gray.700"
                mx={4}
                onClick={() => router.push('/pricing')}
              >
                Pricing
              </Button> */}
              <Button
                as="a"
                variant="link"
                cursor={'pointer'}
                color="gray.700"
                mx={4}
                onClick={() => router.push('/privacy-policy')}
              >
                Privacy
              </Button>
              <Button
                as="a"
                variant="link"
                cursor={'pointer'}
                color="gray.700"
                mx={4}
                onClick={() => router.push('/terms-of-service')}
              >
                Terms
              </Button>
            </Flex>
          </Stack>
        </Stack>
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} WorkGood LLC
        </Text>
      </Stack>
    </Stack>
    {/* <Divider />
    <Stack
      pt={{ base: '8', md: 0 }}
      pb={{ base: '8', md: 0 }}
      justify="space-between"
      direction={{ base: 'column-reverse', md: 'row' }}
      align="center"
    > */}
      {/* <Text fontSize="sm" color="subtle">
        &copy; {new Date().getFullYear()} WorkGood LLC
      </Text> */}
      {/* <ButtonGroup variant="ghost">
        <IconButton
          as="a"
          href="#"
          aria-label="LinkedIn"
          icon={<FaLinkedin fontSize="1.25rem" />}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="GitHub"
          icon={<FaGithub fontSize="1.25rem" />}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="Twitter"
          icon={<FaTwitter fontSize="1.25rem" />}
        />
      </ButtonGroup> */}
    {/* </Stack> */}
  </Container>
);

export default BigFooter;
