export const KEY_ALIAS = {
  command: 'cmd',
  shift: 'shft',
  option: 'opt',
  control: 'ctrl',
  tab: 'tab',
  caps: 'caps lock',
  backspace: 'bckspace',
  delete: 'del',
  enter: 'enter',
  return: 'return',
  meta: 'meta'
};

export default {
  KEY_ALIAS
};
