import React, { FC, ReactElement, useContext } from 'react';
import { AppContext } from 'utils/contexts/AppContext';
import { Flex, Box, Collapse, Link } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { getImageUrl } from 'utils/image';
import router from 'next/router';
import { MobileNav } from 'components/organisms/MobileNav';
import { AppBarContainer } from 'components/molecules/AppBar/components/AppBarContainer';
const Breadcrumbs = dynamic(() => import('components/molecules/Breadcrumbs'));
const UserMenu = dynamic(() => import('components/organisms/UserMenu'));
interface AppLayoutProps {
  appBar?: ReactElement;
  sidebar?: ReactElement;
  sidebarExtension?: ReactElement;
}

const SIDEBAR_WIDTH = 250;
const EXTENSION_WIDTH = 350;

const AppLayout: FC<AppLayoutProps> = function ({
  children,
  sidebar,
  appBar,
  sidebarExtension
}) {
  return (
    <Flex
      direction="column"
      h="100vh"
      bg="gray.200"
      position={'relative'}
      top={0}
      left={0}
    >
      <AppBarContainer>{appBar}</AppBarContainer>
      <Flex h="100%">
        <Flex
          flex={sidebarExtension ? 1 : '0 0 250px'}
          h="100%"
          bg="brand.800"
          display={['none', 'none', sidebar ? 'flex' : 'none']}
          maxW={'600px'}
        >
          <Flex
            key="myapps-list"
            flex={true ? ['0 0 250px'] : '0 0 0'}
            w={true ? { sm: '0px', md: '70px', lg: '250px' } : '0'}
            flexBasis={true ? { sm: '70px', lg: '250px' } : '0'}
            bg="brand.800"
            h={'100%'}
          >
            <Flex width="100%" flexDirection={'column'}>
              <Flex
                flex={'0 0 49px'}
                minH="49px"
                px={4}
                alignItems="center"
                // justifyContent={'center'}
                borderBottom={'1px'}
                borderColor="brand.600"
              >
                <Link
                  _hover={{
                    textDecoration: 'none'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    analytics.track('Logo Clicked', {});
                    router.push('/', '/', { shallow: true });
                  }}
                  href={'/'}
                >
                  <Box
                    display={{ sm: 'none', lg: 'block' }}
                    margin="auto"
                    mt={2}
                    height="100%"
                  >
                    <Image
                      height={32}
                      width={133}
                      src={getImageUrl(
                        'brand/full-transparent-colored-ondark.png'
                      )}
                      alt={`WorkGood`}
                    />
                  </Box>
                  <Box
                    display={{ sm: 'block', lg: 'none' }}
                    margin="auto"
                    mt={2}
                    height="100%"
                  >
                    <Image
                      height={49}
                      width={49}
                      src={getImageUrl('brand/icon-transparent-ondark.png')}
                      alt={`WorkGood`}
                    />
                  </Box>
                </Link>
              </Flex>
              {sidebar && (
                <Collapse in={!!sidebar} unmountOnExit animateOpacity>
                  {sidebar}
                </Collapse>
              )}
            </Flex>
          </Flex>
          <Collapse in={!!sidebarExtension} unmountOnExit>
            <Flex
              flex={'1 1 350px'}
              minW={[0, 0, '350px']}
              maxW={[0, 0, '100%']}
              bg="brand.700"
              h={'100%'}
            >
              {sidebarExtension}
            </Flex>
          </Collapse>
        </Flex>
        <Flex
          flex={2}
          minW={['auto', 'auto', '800px']}
          flexDirection="column"
          maxH="100%"
          width="100%"
          position={'relative'}
        >
          <Flex
            py={2}
            px={5}
            m={0}
            minH={'49px'}
            display={['none', 'flex', 'flex']}
            justifyContent="space-between"
            alignItems={'center'}
            bg={'white'}
            borderBottomWidth={'1px'}
            borderBottomColor={'gray.200'}
            boxShadow={'sm'}
          >
            <Flex alignItems={'center'}>
              {(!sidebar && (
                <Image
                  height={32}
                  width={133}
                  src={getImageUrl(
                    'brand/full-transparent-colored-onwhite.png'
                  )}
                  alt={`WorkGood`}
                />
              )) || <Breadcrumbs />}
            </Flex>
            <UserMenu />
          </Flex>
          <Box h="calc(100% - 50px)" overflow="auto" width={'100%'}>
            {children}
          </Box>
          {/* <Box display={{ sm: 'block', md: 'none' }}>
            <MobileNav />
          </Box> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AppLayout;
