import React, { FC, memo, useContext } from 'react';
import router, { useRouter } from 'next/router';
import Image from 'next/image';
import { Flex, Box, IconButton, Text, Link } from '@chakra-ui/react';
import { getImageUrl } from 'utils/image';
import { IoMdArrowBack } from 'react-icons/io';
import { View } from 'utils/constants';
import { parseUrl, buildUrl } from 'utils/route';
import { AppLogo } from 'components/atoms';
import { AppContext } from 'utils/contexts/AppContext';

export const AppBarContainer: FC = memo(({ children }) => {
  const { app } = useContext(AppContext);
  const homeUrl = `/`;
  const {
    query: { slug },
    pathname
  } = useRouter();
  const { view, platform, collectionId, collectionName, appSlug } = parseUrl(
    slug,
    pathname
  );

  const handleHomeIconClick = (e) => {
    e.preventDefault();
    analytics.track('Home Logo Clicked');
    router.push('/');
    return false;
  };

  const MobileHeaderState: Record<View, any> = {
    [View.HOME]: {
      showLogo: true,
      backButton: false,
      navTitle: null,
      url: () => null
    },
    [View.ADD_APPS]: {
      showLogo: true,
      backButton: false,
      navTitle: null,
      url: () => null
    },
    [View.APP]: {
      showLogo: false,
      backButton: true,
      navTitle: (
        <Flex alignItems={'center'}>
          <AppLogo
            params={['c_pad']}
            dimensions={[24, 24]}
            src={getImageUrl(`logos/${app?.slug}.png`)}
            alt={`${app?.slug} logo`}
          />
          <Text ml={2}>{app?.name}</Text>
        </Flex>
      ),
      url: () => '/apps'
    },
    [View.COLLECTION]: {
      showLogo: false,
      backButton: true,
      navTitle: 'Cheatsheet',
      url: () => buildUrl({ platform, appSlug: appSlug })
    },
    [View.PRACTICE]: {
      showLogo: false,
      backButton: true,
      navTitle: 'Practice',
      url: () =>
        buildUrl({
          platform,
          appSlug: appSlug,
          collectionId: collectionId,
          collectionName: collectionName
        })
    },
    [View.SHORTCUTS]: {
      showLogo: false,
      backButton: true,
      navTitle: 'Cheatsheet',
      url: () => buildUrl({ platform, appSlug: appSlug })
    },

    [View.TEST]: {},
    [View.EXPLORE]: {
      showLogo: false,
      backButton: true,
      navTitle: ''
    }
  };

  const { showLogo, backButton, navTitle, url } = MobileHeaderState[view];

  return (
    <Flex
      display={['flex', 'flex', 'none']}
      align="left"
      minH={'48px'}
      px={4}
      py={2}
      bg="brand.800"
      justify="space-between"
      alignItems="center"
      boxShadow="md"
    >
      <Flex flex={1} align="center" justify="flex-start">
        {showLogo && (
          <Link
            mt={2}
            href={homeUrl}
            _hover={{
              textDecoration: 'none'
            }}
            onClick={handleHomeIconClick}
          >
            <Image
              height={32}
              width={133}
              src={getImageUrl('brand/full-transparent-plain-ondark.png')}
              alt="WorkGood"
            />
          </Link>
        )}
        <Flex color="white" align={'center'}>
          {backButton && (
            <IconButton
              variant={'link'}
              color={'white'}
              icon={<IoMdArrowBack size={20} />}
              aria-label={'Back Button'}
              onClick={() => router.push(url(), url(), { shallow: true })}
            />
          )}

          {navTitle && (
            <Flex>
              {/* <AppLogo
                params={['c_pad']}
                dimensions={[24, 24]}
                src={getImageUrl(`logos/google-sheets.png`)}
                alt={'Google Sheets'}
              /> */}
              <Text ml={1} fontWeight={'semibold'}>
                {navTitle}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      {/* <Box w='100%' textAlign={'center'} mt={1}>
      <Image
        height={26}
        width={110}
        src={getImageUrl('brand/icon-transparent-plain-ondark.png')}
        alt="WorkGood"
      />
      </Box> */}
      {children}
    </Flex>
  );
});

export default AppBarContainer;
