import React, { FC } from 'react';
import Image from 'next/image';
// import router from 'next/router';
import { Grid, Link, GridItem, Flex } from '@chakra-ui/react';
import { Footer } from 'components/molecules/Footer';
import { Page } from 'components/organisms/Page';
import BigFooter from 'components/molecules/BigFooter';
// import Cookie from 'js-cookie';
// import { ACCEPT_COOKIE } from 'utils/constants';
// import { getPlatform } from 'utils/device';

const LandingLayout: FC = function ({ children }) {
  const handleHomeIconClick = (e) => {
    e.preventDefault();
    analytics.track('Home Logo Clicked');
    // const accepts = Cookie.get(ACCEPT_COOKIE);

    // if (accepts) {
    //   router.push(accepts ? `/${getPlatform()}/shortcuts` : '/');
    // }
  };
  return (
    <Page>
      <Grid>
        <GridItem bg="brand.800" justifyItems="center">
          <Flex
            align="center"
            px={4}
            py={[3, 5]}
            m="auto"
            w="100%"
            maxWidth={'1200px'}
            justify={"space-between"}
          >
            <Link onClick={handleHomeIconClick}>
              <Image
                height={36}
                width={150}
                src="/brand/full-transparent-plain-ondark.png"
                alt="WorkGood"
              />
            </Link>
          </Flex>
        </GridItem>
        {children}
        <GridItem justifyItems="center" bg='gray.300'>
          <Flex m="auto" w="100%" maxWidth={'1200px'} justify="space-between">
            <BigFooter />
          </Flex>
        </GridItem>
      </Grid>
    </Page>
  );
};

export default LandingLayout;
